.modalMask {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(26, 26, 26, 0.65);
  height: 100%;
  z-index: 99999991;
  opacity: 0.4;
}

.modalWrap {
  position: fixed;
  overflow: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999992;
  outline: 0;

  .modal {
    width: 600px;
    transform-origin: 507px 63px;
    box-sizing: border-box;
    list-style: none;
    position: relative;
    margin: 0 auto;
    top: 100px;
    padding: 0 0 24px;
    z-index: 99999993;

    .modalContent {
      padding: 10px;
      height: 400px;
      position: relative;
      background-color: #fff;
      border: 0;
      border-radius: 10px;
      background-clip: padding-box;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
      cursor: default;

      .closeBtn {
        position: absolute;
        right: 30px;
        cursor: pointer;
        font-size: 12px;
        font-weight: bold;
      }

      .skipBtn {
        bottom: 20px;
        text-decoration: underline;
      }

      .closeIcon {
        font-family: ArchitectsDaughter;
        right: 10px;
        top: 10px;
        font-weight: bold;
      }

      .tutorial {
        margin: 20px 0 0 10px;
      }

      .skipBtn:hover {
        color: #005796;
      }

      img {
        margin-top: 10px;
        width: 550px;
      }
    }
  }
}
