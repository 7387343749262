.fontSelection {
  position: fixed;
  right: 60px;
  top: 0;
  background-color: white;
  padding: 10px;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.2);

  z-index: 9999999;
  font-size: 10px;
  border-radius: 0 0 10px 10px;

  .item {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;

    select {
      width: 130px;
      margin-left: 10px;
    }
  }

  .colorPicker {
    position: absolute;
    z-index: 2;
    margin-top: 20px;
  }
}
