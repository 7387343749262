.leftPanel {
  width: 250px;
  border: solid 0px #eee;
  border-left-width: 1px;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0px;
  background-color: white;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.2);

  z-index: 9999999;
  font-size: 12px;

  .title {
    /* height: 57px; */
    border: solid 0px #eee;
    border-bottom-width: 1px;
    padding: 17px 10px;
    color: #5f6368;
    font-weight: 400;
    display: flex;
    justify-content: space-between;
  }

  .content {
    height: calc(100vh - 56px);
    overflow: auto;
    padding: 17px 10px;

    .deleteAll {
      margin-bottom: 20px;
      text-align: right;
      cursor: pointer;

      span {
        text-decoration: underline;
      }
    }

    .verticalText {
      margin: auto;
      width: 12px;
      word-wrap: break-word;
    }

    .nothingFound{
      width: 110px;
      margin: auto;
      margin-top:50px;
      color: #5f6368;

      .searchIcon{
        margin: auto;
        width: 50px;
      }
    }
  }

  .item {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    cursor: pointer;
    border: 0px solid #ececec;
    border-bottom-width: 1px;

    .name {
      max-width: 190px;
      word-break: break-all;
    }

    .deleteIcon {
      width: 30px;
      height: 30px;
      cursor: pointer;
      opacity: 0;
      text-align: center;
      border-radius: 15px;
    }

    img {
      width: 14px;
      margin-top: 7px;
    }
  }

  .item:hover {
    border-color: #ccc;

    .deleteIcon {
      background-color: #ececec;
      opacity: 1;
    }
  }
}

.close {
  width: 44px;
}
