.menuBtn {
  width: 20px;
  height: 20px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.menuBtn span {
  display: block;
  position: absolute;
  height: 3px;
  width: 50%;
  background: #5f6368;
  opacity: 1;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.menuBtn span:nth-child(even) {
  left: 50%;
  border-radius: 0 9px 9px 0;
}

.menuBtn span:nth-child(odd) {
  left: 0px;
  border-radius: 9px 0 0 9px;
}

.menuBtn span:nth-child(1),
.menuBtn span:nth-child(2) {
  top: 0px;
}

.menuBtn span:nth-child(3),
.menuBtn span:nth-child(4) {
  top: 6px;
}

.menuBtn span:nth-child(5),
.menuBtn span:nth-child(6) {
  top: 12px;
}

.menuBtn.open span:nth-child(1),
.menuBtn.open span:nth-child(6) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.menuBtn.open span:nth-child(2),
.menuBtn.open span:nth-child(5) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.menuBtn.open span:nth-child(1) {
  left: 0px;
  top: 7px;
  width: 20px;
}

.menuBtn.open span:nth-child(2) {
  left: calc(50% - 10px);
  top: 7px;
  width: 20px;
}

.menuBtn.open span:nth-child(3) {
  left: -50%;
  opacity: 0;
}

.menuBtn.open span:nth-child(4) {
  left: 100%;
  opacity: 0;
}

.menuBtn.open span:nth-child(5) {
  opacity: 0;
}

.menuBtn.open span:nth-child(6) {
  opacity: 0;
}
