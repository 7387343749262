html {
  overflow: initial !important;
}

@font-face {
  font-family: ArchitectsDaughter;
  src: url(./assets/fonts/ArchitectsDaughter.ttf);
}

body {
  margin: 0;

  letter-spacing: 0.5px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  overflow: initial !important;
  background-color: #fbfbfb;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
