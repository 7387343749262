.box {
  position: absolute;
  cursor: default;
  z-index: 9000;
}

.span {
  background-color: transparent;
  border: 1px dashed #999;
  width: 100%;
  height: 100%;
  float: left;
}
